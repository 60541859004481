
@import "../../bootstrap/variables.less";

@body-bg:					#C6A6D9;

@text-color:				#000;
@link-color:				#fff;
@link-hover-color:			#000;
@link-hover-decoration:		underline;

@font-family-base:			Times New Roman, Times, serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				36px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			2px;
@thumb-margin-right:		2px;
@thumb-margin-bottom:		2px;
@thumb-margin-left:			2px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #000;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@pager-color:					#fff;
@pager-color-active:			#000;
@pager-padding:					2px;
@pager-border:					none;
@pager-text-decoration:			underline;
@pager-font-weight-active:		normal;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@social-links-margin:		15px 0 5px;

@sites-list-font-size:				20px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				30px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				40px 5px 10px;
@sites-footer-size:					12px;
@sites-footer-color:				#000;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";
@import "common/pagination.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	color: #fff;
	text-align: center;
	.title {
		color: #000;
	}
}
.links {
	font-size: 18px;
	text-align: center;
	margin: 5px 0;
}
#language-switcher {
	position: absolute;
	top: 0;
	right: 10px;
}
.pagination  {
	display: inline-block;
	vertical-align: sub;
	margin: 0;
	ul li a:hover {
		color: #000;
	}
}